// modules
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { Button, Form } from 'react-bootstrap';
import { RingLoader } from 'react-spinners';
import {
    RainwaterTankNotification,
    handleNotification,
    clearNotification,
} from '../RwtNotification/RwtNotification';
// utils
import ReliabilityTankSizeChart from '../ReliabilityTanksSizeChart';
import {
    calculatingCatchmentSize,
    calculatingDemandValue,
    calculatingReliability,
    calculatingTankSize,
} from '../../../../utils/sizeRainwaterTankUtils';
import { countClick } from '../../../../utils/s3Utils';
import RwtLiveResults from '../RwtLiveResults/RwtLiveResults.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../../../utils/redux/userInfoSlice';
import { selectMapInfo } from '../../../../utils/redux/mapInfoSlice.js';
import { selectSiteDetails } from '../../../../utils/redux/WSUD/siteDetailsSlice.js';
import { selectProjectInfo } from '../../../../utils/redux/projectInfoSlice';
export default function RwtItem({
    id,
    numOfTanks,
    handleInputChange,
    handleClickDeleteButton,
    catchmentArea,
    demandKlDay,
    demandKlYear,
    reliability,
    tankSize,
    mlbName,
    assessorEmail,
    rwtData,
    setHaveRwtResult,
    targetReductions,
    collectImages,
    onCollectImages,
    graphImages,
    setGraphImages,
}) {
    const [calculatingResult, setCalculatingResult] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(true);
    const [showCalculateButtonC, setShowCalculateButtonC] = useState(true);
    const [showCalculateButtonR, setShowCalculateButtonR] = useState(true);
    const [showCalculateButtonD, setShowCalculateButtonD] = useState(true);
    const [showCalculateButtonT, setShowCalculateButtonT] = useState(true);
    const [showGraphButton, setShowGraphButton] = useState(true);
    const [showGraphItButton, setShowGraphItButton] = useState(true);
    const [showGraph, setShowGraph] = useState(true);
    const [reliabilityData, setReliabilityData] = useState([]);
    const [volumeReductionData, setVolumeReductionData] = useState([]);
    const [dischargeReductionData, setDischargeReductionData] = useState([]);
    const [loadingMsfData, setLoadingMsfData] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);
    const chartRef = useRef(null);
    const [buttonPressed, setButtonPressed] = useState(false);
    const userInfo = useSelector(selectUserInfo);
    const mapInfo = useSelector(selectMapInfo);
    const siteInfo = useSelector(selectSiteDetails);
    const projectInfo = useSelector(selectProjectInfo);
    // const [graphImages, setGraphImages] = useState([]);
    const [graphImage1, setGraphImage1] = useState(null);
    const [graphImage2, setGraphImage2] = useState(null);
    const [graphImage3, setGraphImage3] = useState(null);

    useEffect(() => {
        // Update graphImages based on the three graph images
        if (graphImage1 && graphImage2 && graphImage3 != null) {
            setGraphImages([graphImage1, graphImage2, graphImage3].filter(Boolean));
        }
    }, [graphImage1, graphImage2, graphImage3]);
    useEffect(() => {
        if (!buttonPressed) {
            setHaveRwtResult(false);
        } else {
            setButtonPressed(false);
        }
    }, [catchmentArea, demandKlDay, demandKlYear, reliability, tankSize]);

    // Monitor `collectImages` to trigger collection
    useEffect(() => {
        if (collectImages && graphImages.length > 0) {
            onCollectImages(graphImages);
        }
    }, [collectImages, graphImages, onCollectImages]);

    useEffect(() => {
        setIsDataReady(false);
        setShowGraphItButton(true);
        setReliabilityData([]);
        setVolumeReductionData([]);
        setDischargeReductionData([]);
    }, [catchmentArea, demandKlDay, demandKlYear]);
    const addNotification = (message) => {
        handleNotification(
            id,
            message,
            notifications,
            setNotifications,
            setShowCalculateButtonC,
            setShowCalculateButtonR,
            setShowCalculateButtonD,
            setShowCalculateButtonT,
        );
    };
    const removeNotification = (id) => {
        clearNotification(id, notifications, setNotifications);
    };
    const handleButtonClick = async () => {
        setLoadingMsfData(true);
        let demandDaily = demandKlDay * 1000 || (demandKlYear / 365) * 1000;
        let payload = {
            assessorName: userInfo.assessorName,
            assessorEmail: userInfo.assessorEmail,
            developmentType: projectInfo.developmentType,
            catchmentType: 'Split',
            rainfallStation: mapInfo.rainfallStation,
            cityCouncil: mapInfo.council,
            addressMain: mapInfo.address,
            addressSub: '',
            suburb: mapInfo.suburb,
            zipcode: mapInfo.postcode,
            state: mapInfo.state,
            siteArea: catchmentArea,
            rainwaterDemand: 'Toilet + Laundry',
            impervious: true,
            imperviousNodes: [
                {
                    impAreaName: 'Default Catchment',
                    impAreaType: 'Roof',
                    impAreaSize: catchmentArea,
                    impAreaTreatType: 'Rainwater Tank - Above Slab',
                    impAreaTreatSize: tankSize,
                    impAreaBrooms: demandDaily / 62,
                    tn: 'N/A',
                    tp: 'N/A',
                    tss: 'N/A',
                    gp: 'N/A',
                    fr: 'N/A',
                    wsr: 'N/A',
                    results: {},
                },
            ],
            pervious: false,
            perviousNodes: [],
            wqMethod: 'Standard',
            hasWQ: false,
            hasOsd: false,
            eolTreatment: '',
            eolTreatmentSize: '',
            emailMSFToggle: false,
        };

        try {
            const response = await fetch('https://backend.stormupdated.com.au/sails/getMSF', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const data = await response.text();
            const blob = new Blob([data], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${mapInfo.address}.msf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingMsfData(false);
        }
    };
    const downloadChartAsImage = () => {
        const svgElement = chartRef.current.querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));

        img.onload = () => {
            ctx.drawImage(img, 0, 0);
            const a = document.createElement('a');
            a.download = 'chart.png';
            a.href = canvas.toDataURL('image/png');
            a.click();
        };
    };
    /* --- Catchmnent Area Calculation --- */
    const handleCatchmentAreaCalculate = async () => {
        setShowCalculateButtonC(false);
        setHaveRwtResult(true);
        countClick(assessorEmail, 35);
        /* constants */
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        const tankSizeBuf = +tankSize * scale;
        const reliabilityBuf = +reliability;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        setCalculatingResult(true);
        setButtonPressed(true);

        /* validation */
        let inValidInput = false;
        if (demandValue / tankSizeBuf >= 10) {
            addNotification(
                `The demand is too high for this rainwater tank, the model's mathematics can't handle. Please adjust either tank size or demand.`,
            );
            inValidInput = true;
        }
        if (reliabilityBuf < 35) {
            addNotification(`The reliability shouldn't be less than 35!`);
            inValidInput = true;
        }
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }

        /* Start The Calculation */
        await calculatingCatchmentSize(mlbNameBuf, scale, demandValue, reliabilityBuf, tankSizeBuf)
            .then((res) => {
                const message = res.data.results.message;
                const result = (res.data.results.result / scale).toFixed(2);
                const resReliability = res.data.results.result.reliability;
                const resCatchment = res.data.results.result.catchmentArea;

                if (resReliability) {
                    handleInputChange(resReliability, id, 'reliability');
                }
                if (resCatchment) {
                    handleInputChange(resCatchment, id, 'catchmentArea');
                }
                if (result !== -1) handleInputChange(result, id, 'catchmentArea');

                if (message) {
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);
                }
                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating catchment size');
                console.log('Error happened while calculating catchment size', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonC(true);
    };
    /* --- Demand Calculation --- */
    const handleDemandsCalculate = async () => {
        setShowCalculateButtonD(false);
        setHaveRwtResult(true);
        setButtonPressed(true);

        countClick(assessorEmail, 36);
        /* Constants */
        const catchmentAreaBuf = +catchmentArea;
        const tankSizeBuf = +tankSize;
        const reliabilityBuf = +reliability;
        const ratio = +tankSizeBuf / +catchmentArea;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        /* Validation */
        if (reliabilityBuf < 35) {
            addNotification(`The reliability shouldn't be less than 35!`);
            setCalculatingResult(false);
            return;
        }

        /* Start The Calculation */
        setCalculatingResult(true);
        await calculatingDemandValue(
            mlbNameBuf,
            ratio,
            catchmentAreaBuf,
            reliabilityBuf,
            tankSizeBuf,
        )
            .then((res) => {
                const message = res.data.results.message;
                const result = res.data.results.result.toFixed(2);
                if (result !== -1) handleInputChange(result, id, 'demandKlDay');
                if (message)
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);

                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating demand value');
                console.log('Error happened while calculating demand value', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonD(true);
    };
    /* --- Reliability Calculation --- */
    const handleReliabilityCalculate = async () => {
        setShowCalculateButtonR(false);
        setHaveRwtResult(true);
        setButtonPressed(true);

        countClick(assessorEmail, 37);
        /* Constants */
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        const catchmentAreaBuf = +catchmentArea * scale;
        const tankSizeBuf = +tankSize * scale;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        setCalculatingResult(true);
        /* validation */
        let inValidInput = false;
        if (demandValue / +tankSizeBuf >= 10) {
            addNotification(
                `The demand is too high for this rainwater tank, the model's mathematics can't handle. Please adjust either tank size or demand.`,
            );
            inValidInput = true;
        }
        if (+tankSizeBuf < 1000) {
            addNotification(
                'Tank size too small, please solve this problem with a couple of buckets.',
            );
            inValidInput = true;
        }

        // shutdown the process
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }

        /* Start The Calculation */
        await calculatingReliability(mlbNameBuf, scale, catchmentAreaBuf, demandValue, tankSizeBuf)
            .then((res) => {
                const message = res.data.results.message;
                const result = Math.trunc(res.data.results.result);
                if (result !== -1) handleInputChange(result, id, 'reliability');
                if (
                    message &&
                    message === 'This is a conservative reliability.' &&
                    result !== 100
                ) {
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);
                } else if (message && result !== 100)
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);

                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating reliabilitiy');
                console.log('Error happened while calculating reliabilitiy', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonR(true);
    };
    /* --- Tank Size Calculation --- */
    const handleTankSizeCalculate = async () => {
        setShowCalculateButtonT(false);
        setHaveRwtResult(true);
        setButtonPressed(true);

        countClick(assessorEmail, 38);
        // Constants
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        let catchmentAreaBuf = +catchmentArea * scale;
        const reliabilityBuf = +reliability;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        setCalculatingResult(true);
        /* validation */
        console.log('Checking validation...');
        let inValidInput = false;
        if (reliabilityBuf < 35) {
            addNotification(`The reliability shouldn't be less than 35!`);
            inValidInput = true;
        }
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }
        /* Start The Calculation */
        await calculatingTankSize(mlbNameBuf, scale, catchmentAreaBuf, reliabilityBuf)
            .then((res) => {
                const message = res.data.results.message;
                const result = Math.trunc(res.data.results.result / scale);
                if (result !== -1) handleInputChange(result, id, 'tankSize');
                if (message)
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);

                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating tankSize');
                console.log('Error happened while calculating tankSize', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonT(true);
    };

    const calculateVolumeReused = (demandDay, demandYear, reliability) => {
        if (demandDay !== '' && reliability !== '') {
            return ((reliability * demandDay * 365) / 100).toFixed(2); //db
        } else if (demandYear !== '' && reliability !== '') {
            return ((reliability * demandYear) / 100).toFixed(2);
        }
        return 'N/A';
    };

    const calculateFlowGenerated = (sflow, catchmentArea) => {
        const calculatedFlow = sflow * catchmentArea;
        return parseFloat(calculatedFlow.toFixed(2));
    };

    const handleGraphIt = async () => {
        setShowNotifications(false);
        setShowGraphButton(false);
        countClick(assessorEmail, 38);
        setIsDataReady(false);
        // Constants
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        const catchmentAreaBuf = +catchmentArea * scale;
        const reliabilityBuf = +reliability;
        const sflow = (rwtData[0].sflow / rwtData[0].snodearea) * 1000;

        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toLowerCase();

        setCalculatingResult(true);

        // Validation
        let inValidInput = false;
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }
        const reliabilityResults = [];
        const volumeReductionResults = [];
        const dischargeReductionResults = [];
        let tankSize = 1000;
        const maxTankSize = 100000;
        let flowGeneratedValue;
        try {
            flowGeneratedValue = calculateFlowGenerated(sflow, catchmentArea); // Precompute outside loop
        } catch (err) {
            addNotification('Error in calculating flow generated');
            console.log('Error calculating flow generated', err);
            setCalculatingResult(false);
            return;
        }
        while (tankSize <= maxTankSize) {
            try {
                // Calculate reliability for the current tank size
                const res = await calculatingReliability(
                    mlbNameBuf,
                    scale,
                    catchmentAreaBuf,
                    demandValue,
                    tankSize * scale,
                );

                const reliabilityResult = Math.trunc(res.data.results.result);
                // Skip the iteration if reliabilityResult is -1
                if (reliabilityResult === -1) {
                    const message = res.data.results.message;
                    if (message === 'Please increase catchment area.') {
                        addNotification(message);
                        setShowNotifications(true);
                        setCalculatingResult(false);
                        setShowGraphButton(false);
                        return;
                    }
                    tankSize += 2000;
                    continue;
                }
                // Calculate volume reused and flow generated for the current iteration
                const volumeReusedValue = calculateVolumeReused(
                    demandKlDay,
                    demandKlYear,
                    reliabilityResult,
                );
                // Calculate volume reduction and discharge reduction
                const dischargeReduction = (volumeReusedValue / flowGeneratedValue) * 100;
                const discharge = flowGeneratedValue - volumeReusedValue;
                const volDisFlowGen = discharge / flowGeneratedValue;

                reliabilityResults.push({
                    tankSize,
                    reliability: parseFloat(reliabilityResult.toFixed(2)),
                    volumeReduction: parseFloat(volumeReusedValue),
                });

                volumeReductionResults.push({
                    tankSize,
                    volumeReduction: parseFloat(volDisFlowGen.toFixed(3)),
                });

                dischargeReductionResults.push({
                    tankSize,
                    dischargeReduction: parseFloat(dischargeReduction.toFixed(2)),
                    volumeReduction: parseFloat(discharge.toFixed(4)),
                });

                if (reliabilityResults.length >= 5) {
                    const lastFiveReliabilities = reliabilityResults
                        .slice(-5)
                        .map((r) => r.reliability);
                    const allSame = lastFiveReliabilities.every((val, i, arr) => val === arr[0]);

                    if (allSame) {
                        setNotifications([]);
                        break;
                    }
                }

                tankSize += 2000;
            } catch (err) {
                addNotification('Error happened while calculating reliability');
                console.log('Error happened while calculating reliability', err);
                break;
            }
        }

        // Save the results to state
        setNotifications([]);
        setReliabilityData(reliabilityResults);
        setVolumeReductionData(volumeReductionResults);
        setDischargeReductionData(dischargeReductionResults);
        setCalculatingResult(false);
        setShowGraphButton(true);
        setIsDataReady(true);
        setShowGraphItButton(false);
        setShowNotifications(true);
    };
    return (
        <div className="flex-1 w-100 d-flex flex-column align-items-center" style={{ gap: 2 }}>
            <div className="flex-1 w-100 d-flex flex-row" style={{ gap: 5 }}>
                <div
                    className="flex-4 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        borderRight: '1px solid black',
                        gap: 5,
                        flex: '0 0 20%',
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-catchment-area"
                        name="size-rainwater-tank-tool-catchment-area"
                        value={catchmentArea}
                        placeholder="sqm"
                        onChange={(e) => handleInputChange(e.target.value, id, 'catchmentArea')}
                    />
                    {/* Display "Calculate" button or loading spinner */}
                    {showCalculateButtonC ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            style={{ height: '2rem', lineHeight: '1.5rem' }}
                            onClick={handleCatchmentAreaCalculate}
                            disabled={
                                (!demandKlDay && !demandKlYear) ||
                                !reliability ||
                                !tankSize ||
                                calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonC} />
                        </div>
                    )}
                </div>

                {/* Demands */}
                <div
                    className="flex-3 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        borderRight: '1px solid black',
                        gap: 5,
                        flex: '0 0 20%',
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-catchment-demand-kl-day"
                        name="size-rainwater-tank-tool-catchment-demand-kl-day"
                        value={demandKlDay}
                        placeholder="kL/day"
                        onChange={(e) => handleInputChange(e.target.value, id, 'demandKlDay')}
                    />
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-demand-kl-year"
                        name="size-rainwater-tank-tool-demand-kl-year"
                        value={demandKlYear}
                        placeholder="kL/year"
                        onChange={(e) => handleInputChange(e.target.value, id, 'demandKlYear')}
                    />
                    {showCalculateButtonD ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            style={{ height: '2rem', lineHeight: '1.5rem' }}
                            onClick={handleDemandsCalculate}
                            disabled={
                                !catchmentArea || !reliability || !tankSize || calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonD} />
                        </div>
                    )}
                </div>

                {/* Reliability */}
                <div
                    className="flex-2 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        borderRight: '1px solid black',
                        gap: 5,
                        flex: '0 0 12%',
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-reliability"
                        name="size-rainwater-tank-tool-reliability"
                        value={reliability}
                        placeholder="%"
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 100) {
                                handleInputChange(e.target.value, id, 'reliability');
                            } else {
                                addNotification('The reliability should be in range 0 to 100.');
                            }
                        }}
                    />
                    {showCalculateButtonR ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            style={{ height: '2rem', lineHeight: '1.5rem' }}
                            onClick={handleReliabilityCalculate}
                            disabled={
                                (!demandKlDay && !demandKlYear) ||
                                !catchmentArea ||
                                !tankSize ||
                                calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonR} />
                        </div>
                    )}
                </div>

                {/* Tank Size */}
                <div
                    className="flex-3 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        gap: 5,
                        flex: '0 0 15.2%',
                        borderRight: '1px solid black',
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-tank-size"
                        name="size-rainwater-tank-tool-tank-size"
                        value={tankSize}
                        placeholder="Litres"
                        onChange={(e) => handleInputChange(e.target.value, id, 'tankSize')}
                    />
                    {showCalculateButtonT ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            style={{ height: '2rem', lineHeight: '1.5rem' }}
                            onClick={handleTankSizeCalculate}
                            disabled={
                                (!demandKlDay && !demandKlYear) ||
                                !reliability ||
                                !catchmentArea ||
                                calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonT} />
                        </div>
                    )}
                </div>

                <div
                    className="d-flex flex-row"
                    style={{ gap: 5, flex: '0 0 18%', alignItems: 'center' }}
                >
                    {/* Graph It Button */}
                    {calculatingResult ? (
                        <div className="spinner-container" style={{ flex: '1 1 auto' }}>
                            <RingLoader size={30} color="#36D7B7" loading={calculatingResult} />
                        </div>
                    ) : showGraphItButton ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            style={{
                                width: '5vw',
                                height: '2rem',
                                lineHeight: '1.5rem',
                                overflow: 'hidden',
                                marginLeft: '1.5vw',
                            }}
                            onClick={handleGraphIt}
                            disabled={!(demandKlDay || demandKlYear) || !catchmentArea}
                        >
                            Graph It
                        </Button>
                    ) : (
                        // Show/Hide Graph Button
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            style={{
                                width: '5vw',
                                height: '2rem',
                                lineHeight: '1.5rem',
                                overflow: 'hidden',
                                marginLeft: '1.5vw',
                            }} // Fixed height
                            onClick={() => setShowGraph((prevState) => !prevState)}
                            disabled={
                                !demandKlDay &&
                                !demandKlYear &&
                                !reliabilityData.length > 0 &&
                                !catchmentArea &&
                                calculatingResult
                            }
                        >
                            {showGraph ? 'Hide Graph' : 'Show Graph'}
                        </Button>
                    )}
                    <Button
                        type="button"
                        size="sm"
                        variant="success"
                        style={{
                            height: '2rem',
                            width: '6.5vw',

                            lineHeight: '1.5rem',
                            overflow: 'hidden',
                            marginLeft: '1.5vw',
                        }}
                        onClick={handleButtonClick}
                        disabled={
                            (!demandKlDay && !demandKlYear) ||
                            !reliability ||
                            !catchmentArea ||
                            !tankSize ||
                            calculatingResult
                        }
                    >
                        Download Msf
                    </Button>
                    {/* Delete Button */}
                    <span
                        className="d-flex align-items-center"
                        style={{
                            cursor: 'pointer',
                            fontSize: '1rem',
                            flex: '1 1 auto',
                            marginLeft: '1.3vw',
                        }}
                        onClick={() => {
                            if (numOfTanks > 1 || !calculatingResult) {
                                handleClickDeleteButton(id);
                            }
                        }}
                    >
                        X
                    </span>
                </div>
            </div>

            {catchmentArea !== '' && (
                <RwtLiveResults
                    demandDay={demandKlDay}
                    demandYear={demandKlYear}
                    catchmentArea={catchmentArea}
                    reliability={reliability}
                    rwtData={rwtData}
                    targetReductions={targetReductions}
                    tankSize={tankSize}
                />
            )}

            {/* Notifications */}
            {showNotifications && (
                <div className="notification-container" style={{ gap: 2 }}>
                    {notifications.map((n) => (
                        <RainwaterTankNotification
                            key={n.id}
                            message={n.message}
                            onClose={() => removeNotification(n.id)}
                            style={{ height: '26.23px' }}
                        />
                    ))}
                </div>
            )}
            {reliabilityData && isDataReady && showGraph && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <ReliabilityTankSizeChart
                        chartRef={chartRef}
                        data={reliabilityData}
                        downloadChartAsImage={downloadChartAsImage}
                        xAxisLabel="Tank Size (L)"
                        yAxisLabel="Demand Met (%) "
                        yAxisLabel2="Volume reused"
                        setGraphImage={setGraphImage1}
                    />
                    {/* Demand Supplied / yr (kL) */}
                    <ReliabilityTankSizeChart
                        chartRef={chartRef}
                        data={volumeReductionData}
                        downloadChartAsImage={downloadChartAsImage}
                        xAxisLabel="Tank Size (L)"
                        yAxisLabel="Vol Dschrgd/Flow Gnrtd"
                        yAxisLabel2="n/a"
                        setGraphImage={setGraphImage2}
                    />
                    {/* Total Discharge //n /yr(kL) */}
                    <ReliabilityTankSizeChart
                        chartRef={chartRef}
                        data={dischargeReductionData}
                        downloadChartAsImage={downloadChartAsImage}
                        xAxisLabel="Tank Size (L)"
                        yAxisLabel="Discharge Reduction (%)"
                        yAxisLabel2="Volume Discharged"
                        setGraphImage={setGraphImage3}
                    />
                    {/* Total Discharge //n /yr(kL) */}
                </div>
            )}
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { selectUserInfo } from '../../../utils/redux/userInfoSlice';
import { selectMapInfo } from '../../../utils/redux/mapInfoSlice';
import {
    setHasRainwatertankTable,
    setSizeRainwaterTanks,
    rainwaterTankTool,
} from '../../../utils/redux/sizeRainwaterTankToolSlice';
import { haveTableInRainwatertankSchema } from '../../../utils/sizeRainwaterTankUtils';
import { getTargetReductionsAndWqMethod } from '../../../utils/api/lambda';

import RwtItem from './RwtItem/RwtItem';

import './RwtSizing.css';
import RendreDoc from '../../RenderDoc/RenderDoc';
import FormLabel from '../../FormLabel/FormLabel';
import { Spinner } from 'react-bootstrap';

export default function RwtSizingTool({
    isReady,
    methods,
    setRwtTankSizingState,
    rwtData,
    setHaveRwtResult,
    setCollectImages,
    collectImages,
    setCollectedImages,
    graphImages,
    setGraphImages,
}) {
    const dispatch = useDispatch();
    const { hasSizeRainwaterTank, hasRainwatertankTable, sizeRainwaterTanks } =
        useSelector(rainwaterTankTool);
    const userInfo = useSelector(selectUserInfo);
    const mapInfo = useSelector(selectMapInfo);
    const [targetReductions, setTargetReductions] = useState(null);
    const [allGraphImages, setAllGraphImages] = useState([]);

    const handleCollectGraphImages = (images) => {
        setAllGraphImages((prevImages) => [...prevImages, ...images]);
    };
    if (allGraphImages.length > 0) {
        console.log({ allGraphImages });
    }
    useEffect(() => {
        if (collectImages) {
            setCollectedImages(allGraphImages);
            setAllGraphImages([]); // Clear previous images if needed
            setCollectImages(false); // Reset after triggering collection
        }
    }, [collectImages]);
    useEffect(() => {
        if (!mapInfo) return;
        haveTableInRainwatertankSchema(mapInfo?.rainfallStation).then((res) => {
            if (!res) {
                // alert(
                //     'We are not able to optimize the rainwater tank for this location. Please select the other location on the map.',
                // );
            }
            dispatch(setHasRainwatertankTable(res));
        });

        dispatch(
            setSizeRainwaterTanks([
                {
                    id: uuidv4(),
                    catchmentArea: '',
                    demandKlDay: '',
                    demandKlYear: '',
                    reliability: '',
                    tankSize: '',
                },
            ]),
        );
        const fetchMapInfo = async () => {
            if (!mapInfo) return;

            getTargetReductionsAndWqMethod(mapInfo.state, mapInfo.rainfallStation)
                .then((res) => setTargetReductions(res[0]))
                .catch((error) => {
                    console.error(error);
                });
        };
        fetchMapInfo();
    }, [dispatch, mapInfo?.rainfallStation]);

    // if (!methods || (methods instanceof Array && methods.length === 0)) return null;

    const method = methods instanceof Array && methods.length > 0 ? methods[0] : undefined;
    const fields = methods ? method.fields[0].subfields : undefined;
    const handleInputChange = (value, id, columnName) => {
        const regex = /^(?:\d+(\.\d*)?|\.\d+)?$/;
        if (regex.test(value)) {
            const newArray = sizeRainwaterTanks.map((item) =>
                item.id === id ? { ...item, [columnName]: value } : item,
            );
            dispatch(setSizeRainwaterTanks(newArray));
            setRwtTankSizingState(newArray);
        }
    };

    const handleClickDeleteButton = (id) => {
        const newArray = sizeRainwaterTanks.filter((item) => item.id !== id);
        dispatch(setSizeRainwaterTanks(newArray));
        setRwtTankSizingState(newArray);
    };
    return (
        <>
            <div
                id="size-rainwater-tank-tool-container"
                className="size-rainwater-tank-tool-container osd-blue-border"
            >
                <h5>Rainwater Tank Sizing / Reliability Calculator</h5>
                <br />
                {fields ? (
                    <div>
                        <section>
                            <div className="doc-container">
                                {method?.info?.blocks ? (
                                    <RendreDoc blocks={method?.info?.blocks} />
                                ) : null}
                            </div>
                        </section>
                        <div style={{ position: 'relative', marginTop: '1rem' }}>
                            <div
                                style={
                                    hasSizeRainwaterTank && hasRainwatertankTable
                                        ? {}
                                        : {
                                              position: 'absolute',
                                              top: '0',
                                              bottom: '0',
                                              left: '0',
                                              right: '0',
                                              zIndex: '1',
                                              background: 'rgba(201, 236, 186, 0.4)',
                                              borderRadius: '5px',
                                              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                              backdropFilter: 'blur(8px)',
                                              WebkitBackdropFilter: 'blur(8px)',
                                              border: '1px solid rgba(255, 255, 255, 0.3)',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              fontWeight: 'bold',
                                              fontSize: '16px',
                                          }
                                }
                            >
                                {!(hasSizeRainwaterTank && hasRainwatertankTable) && (
                                    <p>This tool is not available for the current location</p>
                                )}
                            </div>
                            <div
                                id="size-rainwater-tank-tool"
                                className="size-rainwater-tank-tool d-flex flex-column gap-1 osd-blue-border"
                                style={{ borderRadius: '5px' }}
                            >
                                {/* The row of tiles with specific widths */}
                                <div
                                    className="flex-1 w-100 text-center d-flex justify-content-center gap-1 align-items-center"
                                    style={{
                                        borderBottom: '1px solid grey',
                                        paddingBottom: 5,
                                    }}
                                >
                                    {/* First column: Takes 25% */}
                                    <div
                                        className="flex-4 gap-1"
                                        style={{
                                            paddingInline: '3px',
                                            borderRight: '1px solid black',
                                            flex: '0 0 20%',
                                        }}
                                    >
                                        <FormLabel info={fields[0]?.info}>
                                            {fields[0].label}
                                        </FormLabel>
                                        <span className="flex-1" />
                                    </div>

                                    {/* Second column: Takes 30% */}
                                    <div
                                        className="flex-3 gap-1"
                                        style={{
                                            paddingInline: '3px',
                                            borderRight: '1px solid black',
                                            flex: '0 0 20%',
                                        }}
                                    >
                                        <FormLabel info={fields[1]?.info}>
                                            {fields[1].label}
                                        </FormLabel>
                                        <span className="flex-1" />
                                    </div>

                                    {/* Third column: Takes equal share of remaining space */}
                                    <div
                                        className="flex-2 gap-1"
                                        style={{
                                            paddingInline: '3px',
                                            borderRight: '1px solid black',
                                            flex: '0 0 12%',
                                        }}
                                    >
                                        <span className="flex-2">
                                            <FormLabel info={fields[2]?.info}>
                                                {fields[2].label}
                                            </FormLabel>
                                        </span>
                                        <span className="flex-1" />
                                    </div>

                                    {/* Fourth column: Also takes equal share of remaining space */}
                                    <div
                                        className="flex-3"
                                        style={{
                                            paddingInline: '3px',
                                            gap: 5,
                                            flex: '0 0 15.2%',
                                            borderRight: '1px solid black',
                                        }}
                                    >
                                        <span className="flex-2">
                                            <FormLabel info={fields[3]?.info}>
                                                {fields[3].label}
                                            </FormLabel>
                                        </span>
                                        <span className="flex-1" />
                                    </div>
                                    <div
                                        className="flex-3"
                                        style={{ paddingInline: '3px', gap: 5, flex: '1' }}
                                    >
                                        <span className="flex-2">
                                            <FormLabel>Functions</FormLabel>
                                        </span>
                                        <span className="flex-1" />
                                    </div>
                                </div>

                                {/* Render tank items */}
                                <div className="flex-1 d-flex flex-column justify-content-center align-items-center gap-1">
                                    {sizeRainwaterTanks.map((tank) => (
                                        <RwtItem
                                            key={tank.id}
                                            id={tank.id}
                                            numOfTanks={sizeRainwaterTanks.length}
                                            handleInputChange={handleInputChange}
                                            handleClickDeleteButton={handleClickDeleteButton}
                                            catchmentArea={tank.catchmentArea}
                                            demandKlDay={tank.demandKlDay}
                                            demandKlYear={tank.demandKlYear}
                                            reliability={tank.reliability}
                                            tankSize={tank.tankSize}
                                            mlbName={mapInfo?.rainfallStation}
                                            assessorEmail={userInfo?.assessorEmail}
                                            rwtData={rwtData}
                                            setHaveRwtResult={setHaveRwtResult}
                                            targetReductions={targetReductions}
                                            collectImages={collectImages}
                                            onCollectImages={handleCollectGraphImages}
                                            graphImages={graphImages}
                                            setGraphImages={setGraphImages}
                                        />
                                    ))}
                                </div>

                                {/* Add another calculation button */}
                                <div style={{ marginTop: '1vh' }}>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary background-image-deep-blue"
                                        onClick={() => {
                                            const newArray = [
                                                ...sizeRainwaterTanks,
                                                {
                                                    id: uuidv4(),
                                                    catchmentArea: '',
                                                    demandKlDay: '',
                                                    demandKlYear: '',
                                                    reliability: '',
                                                    tankSize: '',
                                                },
                                            ];
                                            dispatch(setSizeRainwaterTanks(newArray));
                                            setRwtTankSizingState(newArray);
                                        }}
                                    >
                                        + ADD ANOTHER CALCULATION
                                    </button>
                                    <span style={{ marginLeft: 10, fontWeight: 700 }}>
                                        *This tool is in "Beta", there may be a couple of errors for
                                        corner cases.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.5,
                            gap: '0.5rem',
                        }}
                    >
                        {!isReady ? (
                            <>
                                <Spinner animation="border" size="sm" />
                                <span>Loading ...</span>
                            </>
                        ) : (
                            <p>Failed to load data</p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

import React from 'react';
import { StyleSheet, View, Page, Text, Image } from '@react-pdf/renderer';

import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import Table from '../Table';
import PDFRenderDoc from '../PDFRenderDoc';
import { isNullish } from '../../../validations/common';

// using https://lingojam.com/SuperscriptGenerator to generate superscripts (<sup>2</sup> is not accept by React PDF)

function round(num) {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
}

function check(num) {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
}

const getClimatePercentage = (num) => {
    if (num === 1) {
        return 'No climate change adjustment';
    } else if (num < 1) {
        return '-' + round((1 - num) * 100) + '%';
    } else {
        return '+' + round((num - 1) * 100) + '%';
    }
};

function getAdjustedStorageVolume(num, adjustment) {
    return round(num * adjustment);
}

const defaultStyles = {
    borderStyle: '1px solid #658cbb',
    textAlignment: 'center',
    headerBorder: true,
    fontSize: 9,
    headerFontSize: 9,
    headerTextAlign: 'center',
};

const createStyles = (newStyles = defaultStyles) => {
    return {
        section: {
            marginTop: 10,
            flexGrow: 1,
        },
        table: {
            display: 'table',
            width: '100%',
            border: newStyles.borderStyle,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
        },

        tableRow: {
            width: '100%',
            flexDirection: 'row',
            borderBottom: newStyles.borderStyle,
        },

        headerCell: {
            fontSize: newStyles.headerFontSize,
            textAlign: newStyles.headerTextAlign,
            borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
            borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
            height: '100%',
            padding: 5,
        },

        tableCell: {
            fontSize: newStyles.fontSize,
            textAlign: newStyles.textAlignment,
            borderRight: newStyles.borderStyle,
            borderLeft: newStyles.borderStyle,
            height: '100%',
            padding: 5,
        },
    };
};

const generateAllTableData = (data) => {
    return [
        !data.osd.psd
            ? {
                  columns: [
                      'Pre-Development Site Details',
                      'Area (m²)',
                      'Runoff Coefficient',
                      'Uncontrolled Area (m²)',
                  ],
                  items: data.osd.preDevAreas.areas.map((obj) => {
                      return [obj.areaName, obj.surfaceArea, obj.runoffCoefficient, 'N/A'];
                  }),
                  styles: createStyles(),
              }
            : {},

        {
            columns: [
                'Post-Development Site Details',
                'Area (m²)',
                'Runoff Coefficient',
                'Uncontrolled Area (m²)',
            ],
            items: data.osd.postDevAreas.areas.map((obj) => {
                return [obj.areaName, obj.surfaceArea, obj.runoffCoefficient, obj.uncontrolled];
            }),
            styles: createStyles(),
        },

        {
            columns: ['', 'Pre-Development', 'Post-Development', 'Uncontrolled'],
            items: [
                [
                    'Total Site Area (m²)',
                    round(data.osd.sumPreDevAreas),
                    round(data.osd.sumPostDevAreas),
                    round(data.osd.sumUncontrolledAreas),
                ],

                [
                    'Weighted Runoff Coefficient',
                    round(data.osdResult.Cpre),
                    round(data.osdResult.Cpost),
                    round(data.osdResult.Cu),
                ],
            ],
            cellWidths: ['33%', '22%', '23%', '22%'],
            styles: createStyles(),
        },

        {
            columns: ['Catchment Times (minutes)'],
            items: [
                ['Time of Concentration of catchment (Tc):', data.osd.catchmentTimeTc + ' mins'],

                [
                    'Time of Concentration of catchment to site outlet (Tcs):',
                    data.osd.catchmentTimeTcs + ' mins',
                ],
                [
                    'Time of Concentration of site outlet to catchment outlet (Tso):',
                    check(data.osd.catchmentTimeTc - data.osd.catchmentTimeTcs) + ' mins',
                ],
            ],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: 'none',
                textAlignment: 'left',
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
            cellWidths: ['70%', '22%'],
            headerWidths: ['100%'],
        },
        {
            columns: ['Storage Design:'],
            items: [
                ['Storage Type: ', data.storageType],

                ['Rainfall Zone: ', 'Latitude: ' + data.latitude, 'Longitude: ' + data.longitude],
                ['AED for PSD (%):', data.osd.aepPSD, 'AEP for OSD (%):      ' + data.osd.aepOSD],
            ],
            cellWidths: ['20%', '40%', '40%'],
            headerWidths: ['100%'],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: 'none',
                textAlignment: 'left',
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },

        {
            columns: ['Storm Duration and Intensity'],
            items: [
                [
                    'Flow',
                    'Tc (mins): ' + data.osd.catchmentTimeTc,
                    'I (mmm/hr): ' + round(data.osdResult.i_PSD),
                    'Storage',
                    'Td (mins): ' + round(data.osdResult.td),
                    'I (mm/hr): ' + round(data.osdResult.i_OSD),
                ],
            ],
            headerWidths: ['100%'],
            cellWidths: ['9%', '16%', '24%', '13%', '19%', '19%'],
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },
        {
            columns: ['Flow Calculations and Storage Details'],
            items: [
                ['Pre-development peak site inflow (L/s):', round(data.osdResult.Qp)],
                ['Uncontrolled peak site inflow (L/s):', round(data.osdResult.Qu)],
                ['Post-development peak site inflow for PSD (L/s):', round(data.osdResult.Qa)],
                ['Post-development peak site inflow for OSD (L/s):', round(data.osdResult.Qa_dash)],
                [
                    'Calculated PSD (L/s):',
                    data.osd.psd === false ? round(data.osdResult.PSD) : 'N/A',
                ],
                ['Nominated PSD (L/s):', round(data.osdResult.PSD)],
                ['Required Storage Volume (m³)', round(data.osdResult.onSiteDetentionVolume)],
                data.osd.climateAdjustment !== '1'
                    ? [
                          'Climate Change (% increase of required storage volume):',
                          getClimatePercentage(parseFloat(data.osd.climateAdjustment)),
                      ]
                    : [],
                data.osd.climateAdjustment !== '1'
                    ? [
                          'Adjusted Required Storage Volume (m³):',
                          getAdjustedStorageVolume(
                              data.osdResult.onSiteDetentionVolume,
                              data.osd.climateAdjustment,
                          ),
                      ]
                    : [],
                data.osdResult.orificeDiameter !== 'N/A'
                    ? ['Height of storage above orifice (m):', round(data.osd.tankHeight)]
                    : [],

                data.osdResult.orificeDiameter !== 'N/A'
                    ? ['Required Orifice Diameter (mm):', round(data.osdResult.orificeDiameter)]
                    : [],
            ],
            cellWidths: ['70%', '30%'],
            headerWidths: ['100%'],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: 'none',
                textAlignment: 'left',
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },
    ];
};

const RWTDemandReportTemplate = ({ data, reportHeaderIcon }) => {
    const showMap = {
        indoorCalcState: data.indoorCalcState.result !== undefined,
        irrigationCalcState: data.irrigationCalcState.result !== undefined,
        occupancyCalcState: data.occupancyCalcState.result !== undefined,
        rwtSizingState: data.rwtSizingState.result !== undefined,
    };

    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            minHeight: '100%', // Minimum height of an A4 page in points
            height: '1500',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        subtitle: {
            fontSize: 12,
            fontweight: 500,
            marginTop: 10,
            marginBottom: 10,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        pageContent: {
            minHeight: '100%', // Minimum height of an A4 page in points
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        methodInfo: {
            margin: 10,
            padding: 20,
            backgroundColor: '#add8e6',
            borderRadius: 10,
        },
    });

    const titles = {
        indoorCalcState: 'Indoor Demand Calculator',
        occupancyCalcState: 'Indoor Occupancy Calculator',
        irrigationCalcState: 'Irrigation Demand Calculator',
        rwtSizingState: 'Rainwater Tank Sizing / Reliability Calculator',
    };

    const resultLabel = {
        indoorCalcState: 'Calculated Total Indoor Demand, kL/Day',
        occupancyCalcState: 'Calculated Total Occupants',
        irrigationCalcState: 'Calculated Total Demand, kL/Year',
        rwtSizingState: null,
    };

    const endNotes = {
        occupancyCalcState: '',
        indoorCalcState:
            '* Please note, this is always considered Demand PER DAY, and should be inserted in the modelling in the “Demand Per Day” field.',
        irrigationCalcState:
            '* Please note, this is always considered Demand PER YEAR, and should be inserted in the modelling in the “Demand Per Year” field.',
        rwtSizingState: '',
    };

    const constructTable = (fields, calculatorIndex, isSubfields = false) => {
        // Filter out fields with subfields
        fields = Object.fromEntries(
            Object.entries(fields).filter(([_, value]) => !('subfields' in value)),
        );

        const widths = [];
        const numFields = Object.keys(fields).length;

        let tableData = [];
        if (isSubfields && numFields > 0) {
            const keys = Object.keys(fields);
            // Get the number of values
            const numValues = fields[keys[0]].value.length;
            // Create the new data structure for tableData
            for (let i = 0; i < numValues; i++) {
                const row = keys.map((key) => fields[key].value[i]);
                tableData.push(row);
            }
        } else {
            tableData.push(Object.keys(fields).map((field) => fields[field].value));
        }

        if (numFields === 0) return;
        Object.keys(fields).forEach((_) => widths.push(`${100 / numFields}%`));
        return {
            tableKey: calculatorIndex,
            columnNames: Object.keys(fields).map((field) => fields[field].label),
            tableData: tableData,
            cellWidths: widths,
            tableStyles: createStyles(),
        };
    };

    const calculatorsToRender = Object.keys(data).filter((calculator) => showMap[calculator]);

    return (
        <View>
            {calculatorsToRender.map((calculator, calculatorIndex) => {
                let tables = [];

                if (
                    calculator === 'occupancyCalcState' &&
                    data[calculator].result &&
                    data[calculator].method.id === 'SouthAustraliaMUSICModellingGuidelines2021'
                ) {
                    // Subfields table
                    tables.push(
                        <Table
                            key={`subfields-table-${calculatorIndex}`} // Add a unique key
                            {...constructTable(
                                data[calculator].fields.areas.subfields,
                                'areas',
                                true,
                            )}
                        />,
                    );
                }

                if (calculator === 'rwtSizingState' && !isNullish(data[calculator].result)) {
                    // Subfields table
                    tables.push(
                        <Table
                            key={`calculations-table-${calculatorIndex}`} // Add a unique key
                            {...constructTable(
                                data[calculator].fields.calculations.subfields,
                                'calculations',
                                true,
                            )}
                        />,
                    );
                }

                if (Object.keys(data[calculator].fields).length > 0) {
                    // Normal fields table
                    const props = constructTable(data[calculator].fields, calculatorIndex);
                    if (props)
                        tables.push(
                            <Table
                                key={`normal-fields-table-${calculatorIndex}`} // Add a unique key
                                {...props}
                            />,
                        );
                }

                return data[calculator].method ? (
                    <View key={`page-${calculatorIndex}`} style={styles.pageContent}>
                        <Page style={styles.body} wrap size="A4">
                            <ReportHeader reportHeaderUrl={reportHeaderIcon} />
                            <View style={{ height: '85%' }}>
                                <Text style={styles.mainTitle}>{titles[calculator]}</Text>
                                {data[calculator].method?.label && (
                                    <Text
                                        style={[styles.subtitle, { textDecoration: 'underline' }]}
                                    >
                                        Method: {data[calculator].method.label}
                                    </Text>
                                )}
                                {data[calculator].method?.info?.blocks && (
                                    <View style={styles.methodInfo}>
                                        <PDFRenderDoc
                                            blocks={data[calculator].method?.info?.blocks}
                                        />
                                    </View>
                                )}
                                <View>
                                    {tables}
                                    {resultLabel[calculator] && (
                                        <Text style={[styles.subtitle, { fontSize: 10 }]}>
                                            {resultLabel[calculator]}: {data[calculator].result}
                                        </Text>
                                    )}
                                </View>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    {Array.isArray(data[calculator]?.graphs) &&
                                        data[calculator]?.graphs.map((graph) => {
                                            return (
                                                <Image
                                                    src={graph}
                                                    style={{
                                                        padding: 8,
                                                        height: 'auto',
                                                        width: `${100 / data[calculator]?.graphs.length}%`,
                                                    }}
                                                />
                                            );
                                        })}
                                </View>

                                <Text style={[styles.subtitle, { fontSize: 9, color: 'red' }]}>
                                    {endNotes[calculator]}
                                </Text>
                            </View>
                            <ReportFooter />
                        </Page>
                    </View>
                ) : null;
            })}
        </View>
    );
};

export default RWTDemandReportTemplate;

import React, { useEffect, useRef, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import html2canvas from 'html2canvas';
import { Button } from 'react-bootstrap';

const ReliabilityTankSizeChart = ({
    data,
    xAxisLabel = 'Tank Size (L)',
    yAxisLabel = 'Value',
    yAxisLabel2 = undefined,
    setGraphImage,
}) => {
    const chartRef = useRef(null);
    const [chartImage, setChartImage] = useState(null);
    //console.log({ chartImage });

    useEffect(() => {
        const captureChart = async () => {
            if (chartRef.current) {
                const canvas = await html2canvas(chartRef.current);
                const imageData = canvas.toDataURL('image/png');
                setChartImage(imageData);
                setGraphImage(imageData);
            }
        };
        captureChart();
    }, [data, xAxisLabel, yAxisLabel, setGraphImage]);

    const downloadChartAsImage = () => {
        if (chartImage) {
            const link = document.createElement('a');
            link.href = chartImage;
            link.download = 'storm_durations_chart.png';
            link.click();
        }
    };

    const lineColorMap = {
        reliability: '#82ca9d',
        volumeReduction: '#8884d8',
        dischargeReduction: '#ff7300',
    };

    const lineColor =
        yAxisLabel === 'Reliability (%)'
            ? lineColorMap.reliability
            : yAxisLabel === 'Volume Reduction (m³)'
              ? lineColorMap.volumeReduction
              : lineColorMap.dischargeReduction;

    return (
        <div className="reliability-chart-container">
            <div ref={chartRef} className="chart-container">
                <LineChart
                    width={375}
                    height={300}
                    data={data}
                    margin={{ top: 50, left: 50, bottom: 50, right: 30 }}
                >
                    <Line type="monotone" dataKey={Object.keys(data[0])[1]} stroke={lineColor} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                        dataKey="tankSize"
                        label={{
                            value: xAxisLabel,
                            position: 'insideBottom',
                            textAnchor: 'middle',
                            offset: -15,
                            fontSize: 14,
                        }}
                    />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'middle',
                            textAnchor: 'middle',
                            dx: -40,
                            dy: 0,
                            fontSize: 15,
                        }}
                    />
                    <Tooltip
                        content={
                            <CustomTooltip yAxisLabel={yAxisLabel} yAxisLabel2={yAxisLabel2} />
                        }
                    />
                </LineChart>
            </div>
        </div>
    );
};

const CustomTooltip = ({ active, payload, label, yAxisLabel, yAxisLabel2 }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    backgroundColor: '#fff',
                }}
            >
                <p style={{ color: '#82ca9d' }}>{`Tank Size: ${label} L`}</p>
                <p style={{ color: '#8884d8' }}>{`${yAxisLabel}: ${payload[0].value}`}</p>
                {yAxisLabel2 && yAxisLabel2 !== 'n/a' && (
                    <p
                        style={{ color: '#ff7300' }}
                    >{`${yAxisLabel2}: ${payload[0].payload.volumeReduction}`}</p>
                )}
            </div>
        );
    }
    return null;
};

export default ReliabilityTankSizeChart;

// modules
import React, { useState, useEffect, useRef } from 'react';

// utils
import { sails_api } from '../../../utils/globalConstant';
import { useCyValue } from '../../../hooks/useCyValue';
import { preDevAreasSchema, postDevAreasSchema } from '../../../schemas/osdSchemas';

// components
import DesignStorm from '../components/DesignStorm';
import PreDevAreas from '../components/PreDevAreas';
import PostDevAreas from '../components/PostDevAreas';
import Optional from '../components/Optional';
import { StorageTypeSelection } from '../StorageTypeSelection';
import OsdsAds from '../osds-ads/OsdsAds';
import CalculateOsdResultButton from '../components/CalculateOsdResultButton';
import TCCalculatorModal from '../tc-calculator/TCCalculatorModal';
import OsdResultsTable from '../components/OsdResultTable';
import { getCompanyDetails } from '../../../utils/osdUtils';
import { recordCalculateOSD } from '../../../utils/s3Utils';

export default function RationalTemplate({
    AEPS,
    useOSDCompany,
    useStorageType,
    setOSDMaterial,
    userInfo,
    mapInfo,
    osdMethod,
    useOSDResultsData,
    setTemplateData,
    savedModel,
}) {
    const isInitialMount = useRef(true);

    /* Manufacture Data */
    const [osdCompany, setOSDCompany] = useOSDCompany;
    const [storageType, setStorageType] = useStorageType;
    const [osdResultData, setOsdResultData] = useOSDResultsData;

    const [tcCalculatorData, setTcCalculatorData] = useState(
        savedModel?.tcData ?? { pre: null, post: null, uncontrolled: null },
    );

    const [companyDetails, setCompanyDetails] = useState([]);
    /* Node Data */
    const [TC, setTC] = useState({
        pre: savedModel?.tcPre ?? '',
        post: savedModel?.tcPost ?? '',
        uncontrolled: savedModel?.tcU ?? '',
        psd: savedModel?.councilPSD ?? '',
    });

    // design Storm
    const [designStorm, setDesignStorm] = useState({
        preDevDesignStorm: savedModel?.designStorm?.preDevDesignStorm ?? 1,
        postDevDesignStorm: savedModel?.designStorm?.preDevDesignStorm ?? 1,
    });
    // pre-dev
    const [preDevAreas, setPreDevAreas] = useState(
        savedModel?.preDevAreas?.areas ?? [
            {
                id: 0,
                areaName: '',
                surfaceArea: '',
                impervious: true,
                cy: 0,
            },
        ],
    );
    // post-dev
    const [postDevAreas, setPostDevAreas] = useState(
        savedModel?.postDevAreas?.areas ?? [
            {
                id: 0,
                areaName: '',
                surfaceArea: '',
                uncontrolled: '0',
                impervious: true,
                cy: 0,
            },
        ],
    );
    // cy value
    const { calculatedCy, isLoadingCyValue } = useCyValue(
        'Rational Method',
        designStorm,
        mapInfo,
        { preDevAreas, setPreDevAreas },
        { postDevAreas, setPostDevAreas },
    );
    /* Optional */
    const [climateChangeRate, setClimateChangeRate] = useState(savedModel?.climateChangeRate ?? '');
    const [tankHeight, setTankHeight] = useState(savedModel?.tankHeight ?? '');

    /* Display/Loading */
    const [osdResultLoading, setOsdResultLoading] = useState(false);
    const [showTCCalculator, setShowTCCalculator] = useState('');

    /* Calculated Data */
    const sumPreDevAreas = preDevAreas.reduce((acc, area) => acc + +area.surfaceArea, 0);
    const sumPostDevAreas = postDevAreas.reduce((acc, area) => acc + +area.surfaceArea, 0);
    const sumUncontrolledAreas = postDevAreas.reduce((acc, area) => acc + +area.uncontrolled, 0);

    useEffect(() => {
        if (storageType !== '') {
            getCompanyDetails(storageType, mapInfo.rainfallStation).then((newCompanyDetails) => {
                if (!newCompanyDetails.length) {
                    setStorageType('N/A');
                    setOSDMaterial('N/A');
                }
                setCompanyDetails(newCompanyDetails);
            });
        }
    }, [storageType]);

    /**
     * Parse loaded model data
     */
    useEffect(() => {
        if (savedModel?.preDevAreas?.areas) {
            const result = preDevAreasSchema.safeParse({ areas: savedModel?.preDevAreas?.areas });
            setPreDevAreas(result.data.areas);
        }
        if (savedModel?.postDevAreas?.areas) {
            const result = postDevAreasSchema.safeParse({ areas: savedModel?.postDevAreas?.areas });
            setPostDevAreas(result.data.areas);
        }
    }, [savedModel]);

    // click on CALCULATE  OSD button
    const computeOSDResults = async () => {
        setTemplateData(null);
        setOSDCompany(osdCompany);
        setOsdResultLoading(true);

        const { data: parsedPreDevAreas } = preDevAreasSchema.safeParse({
            areas: preDevAreas,
        });

        const { data: parsedPostAreas } = postDevAreasSchema.safeParse({
            areas: postDevAreas,
        });

        const osdRoute = '/OSD/osdResults/OsdRational';
        const osdPayload = {
            method: osdMethod + ' Method',
            psd: TC.psd,
            councilPSD: TC.psd,
            preDevAep: designStorm.preDevDesignStorm,
            postDevAep: designStorm.postDevDesignStorm,
            preDevAreas: parsedPreDevAreas,
            postDevAreas: parsedPostAreas,
            hasTcPre: TC.pre,
            tcPre: TC.pre,
            hasTcPost: TC.post,
            tcPost: TC.post,
            hasTcU: TC.uncontrolled,
            tcU: TC.uncontrolled,
            tankHeight,
            designStorm: designStorm.preDevDesignStorm,
            climateChangeRate,
            climateAdjustment: 1 + climateChangeRate / 100,
        };

        console.log('Request (Rational): ', osdPayload);

        await sails_api
            .post(osdRoute, {
                latitude: mapInfo.coordinate.lat,
                longitude: mapInfo.coordinate.lng,
                osd: osdPayload,
                totalImpArea: +sumPostDevAreas,
                totalPArea: 0,
            })
            .then((res) => {
                if (res.data.message) alert(res.data.message);
                else {
                    console.log('Response (Rational): ', res.data);
                    setOsdResultData(res.data);
                    recordCalculateOSD({
                        name: userInfo.assessorName,
                        email: userInfo.assessorEmail,
                        coordinates: `${mapInfo.coordinate.lat}, ${mapInfo.coordinate.lng}`,
                        catchment_area: sumPostDevAreas,
                        council: mapInfo.council,
                        address: mapInfo.address,
                        storage_type: storageType,
                        tank_specified: osdCompany,
                        psd: res.data.q_pre,
                        volume: res.data.max_volume,
                        height_above_orifice: tankHeight,
                        orifice_diameter: res.data.report?.orifice?.orificeDiameter,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                alert('Computing OSD result error!');
            });

        setTemplateData({
            ...osdPayload,
            OSDs4VIPs: true,
            sumPreDevAreas: sumPreDevAreas,
            sumPostDevAreas: sumPostDevAreas,
            sumUncontrolledAreas: sumUncontrolledAreas,
            tcData: tcCalculatorData,
        });
        setOsdResultLoading(false);
    };

    /* Hide the OSD result table if the user changed any input */
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        const resetOSDData = () => {
            setOsdResultData(null);
            setTemplateData(null);
        };

        resetOSDData();
    }, [designStorm, TC, preDevAreas, postDevAreas, climateChangeRate, tankHeight]);

    return (
        <>
            <DesignStorm designStorm={designStorm} setDesignStorm={setDesignStorm} AEPS={AEPS} />
            <br />
            {/* Pre-Development Areas */}
            <PreDevAreas
                preDevAreas={preDevAreas}
                setPreDevAreas={setPreDevAreas}
                TC={TC}
                setTC={setTC}
                sumPreDevAreas={sumPreDevAreas}
                sumPostDevAreas={sumPostDevAreas}
                setShowTCCalculator={setShowTCCalculator}
                calculatedCy={calculatedCy}
                options={{
                    qudm: true,
                }}
            />
            <br />
            {/* Post-Development Areas */}
            <PostDevAreas
                postDevAreas={postDevAreas}
                setPostDevAreas={setPostDevAreas}
                TC={TC}
                setTC={setTC}
                sumPreDevAreas={sumPreDevAreas}
                sumPostDevAreas={sumPostDevAreas}
                setShowTCCalculator={setShowTCCalculator}
                calculatedCy={calculatedCy}
            />
            <br />
            {/* optional */}
            <Optional
                climateChangeRate={climateChangeRate}
                setClimateChangeRateChange={setClimateChangeRate}
                tankHeight={tankHeight}
                setTankHeight={setTankHeight}
            />
            <br />

            {/* OSD Material */}
            <div>
                <StorageTypeSelection setStorageType={setStorageType} />
            </div>

            {storageType && (
                <>
                    <br />
                    <OsdsAds
                        companyDetails={companyDetails}
                        setOSDMaterial={setOSDMaterial}
                        osdCompany={osdCompany}
                        setOSDCompany={setOSDCompany}
                        storageType={storageType}
                    />
                </>
            )}
            <br />
            {/* Calculate OSD Result Button */}
            <div>
                <CalculateOsdResultButton
                    TC={TC}
                    preDevAreas={preDevAreas}
                    postDevAreas={postDevAreas}
                    tankHeight={tankHeight}
                    storageType={storageType}
                    osdCompany={osdCompany}
                    computeOSDResults={computeOSDResults}
                    osdResultLoading={osdResultLoading}
                    disabled={
                        (!TC.psd && +sumPreDevAreas - +sumPostDevAreas !== 0) ||
                        (tankHeight !== '' && +tankHeight === 0)
                    }
                    userInfo={userInfo}
                />
            </div>

            {/* Results Table */}
            {osdResultData && Object.keys(osdResultData).length !== 0 && (
                <>
                    <br />
                    <OsdResultsTable
                        preDevDesignStorm={designStorm.preDevDesignStorm}
                        postDevDesignStorm={designStorm.postDevDesignStorm}
                        tableData={{
                            aboveGroundStorage: {
                                permissibleDischarge: TC.psd
                                    ? TC.psd
                                    : parseFloat(osdResultData.q_pre).toFixed(2),
                                onSiteDetentionVolume: (
                                    osdResultData.max_volume *
                                    (1 + climateChangeRate / 100)
                                ).toFixed(2),
                                orificeDiameter:
                                    osdResultData.orificeDiameter === 'N/A'
                                        ? 'N/A'
                                        : parseFloat(+osdResultData.orificeDiameter).toFixed(2) +
                                          'mm',
                            },
                        }}
                    />
                    <br />
                </>
            )}
            <TCCalculatorModal
                type={showTCCalculator}
                tcCalculatorData={tcCalculatorData[showTCCalculator]}
                onClose={() => setShowTCCalculator('')}
                onDone={(tcCalculatorData, finalResult) => {
                    setTC((prev) => ({ ...prev, [showTCCalculator]: finalResult }));
                    setTcCalculatorData((prev) => ({
                        ...prev,
                        [showTCCalculator]: tcCalculatorData,
                    }));
                }}
            />
            <br />
        </>
    );
}
